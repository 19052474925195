@media only screen and (min-width: 768px) {
  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(6, 30vh);
    grid-gap: 30px;
    margin-bottom: 10px;
  }

  .grid-item-0 {
    grid-column: 1/2;
    grid-row: 1/2;
  }

  .grid-item-1 {
    grid-column: 2/3;
    grid-row: 1/2;
  }

  .grid-item-2 {
    grid-column: 3/4;
    grid-row: 1/2;
  }

  .grid-item-3 {
    grid-column: 1/2;
    grid-row: 2/3;
  }

  .grid-item-4 {
    grid-column: 2/3;
    grid-row: 2/3;
  }

  .grid-item-5 {
    grid-column: 3/4;
    grid-row: 2/3;
  }

  .grid-item-6 {
    grid-column: 1/2;
    grid-row: 3/4;
  }

  .grid-item-7 {
    grid-column: 2/4;
    grid-row: 3/4;
  }

  .grid-item-8 {
    grid-column: 1/2;
    grid-row: 4/5;
  }

  .grid-item-9 {
    grid-column: 2/3;
    grid-row: 4/5;
  }

  .grid-item-10 {
    grid-column: 3/4;
    grid-row: 4/5;
  }

  .grid-item-11 {
    grid-column: 2/4;
    grid-row: 5/6;
  }
  .grid-item-12 {
    grid-column: 1/2;
    grid-row: 5/7;
  }
  .grid-item-13 {
    grid-column: 2/3;
    grid-row: 6/7;
  }
  .grid-item-14 {
    grid-column: 3/4;
    grid-row: 6/7;
  }
  .grid-item-15 {
    grid-column: 3/4;
    grid-row: 6/7;
  }
}

.grid-item {
  background-color: transparent;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: xx-large;
}

.grid-item-12 img {
  transform: rotate(-10deg);
}

@media only screen and (max-width: 768px) {
  .grid-item {
    margin-bottom: 5px;
    height: 300px;
  }

  .grid-item-6,
  .grid-item-7,
  .grid-item-8,
  .grid-item-9,
  .grid-item-10,
  .grid-item-11,
  .grid-item-12 {
    display: none;
  }
}
